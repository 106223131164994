.pc-case-carousel-box{
  overflow: hidden;
}
/* 外层容器 */
.swiper3d {
  width: 100% !important;
  height: 37rem;
  /* overflow: hidden; */
  margin: 0 auto;
}

/* 轮播项 */
.swiper3d .swiper-slide {
  width: 80%;
  height: 100%;
  font-weight: 500;
  color: #ffffff;
  position: relative;
  /* 文字垂直居中 */
  /* display: flex;
  justify-content: center;
  align-items: center; */
  -moz-transition: all 0.01s ease-out !important;
}

.swiper3d .swiper-slide .swiper3d-box {
  width: 100%;
  height: 100%;
}

.swiper-3d .swiper-cube-shadow,
.swiper-3d .swiper-slide,
.swiper-3d .swiper-slide-shadow,
.swiper-3d .swiper-slide-shadow-bottom,
.swiper-3d .swiper-slide-shadow-left,
.swiper-3d .swiper-slide-shadow-right,
.swiper-3d .swiper-slide-shadow-top,
.swiper-3d .swiper-wrapper {
  /* transform-style: initial !important; */
  -moz-transform-style:initial !important;
}

.swiper3d .swiper-slide .swiper3d-box img {
  width: 67rem;
  height: 100%;
  display: block;
  cursor: pointer !important;
  margin: 0 auto;
}

.swiper3d .swiper-slide .swiper3d-desc {
  position: absolute;
  bottom: 2.25rem;
  left:10%;
  color: #fff;
  cursor: pointer !important;
}

/* .swiper3d .swiper-slide {
  transition: all 2s ease-in-out;
} */

.swiper3d .swiper-slide .swiper3d-desc .label {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  cursor: pointer !important;
}

.swiper3d .swiper-slide .swiper3d-desc .more img {
  margin-bottom: 0.15rem;
  margin-left: 0.3rem;
  width: 0.5rem;
  height: auto;
  object-fit: contain;
  cursor: pointer !important;
}

.swiper3d .swiper-slide-next,
.swiper3d .swiper-slide-prev {
  opacity: 0.3 !important;
  border: none !important;
  box-shadow: initial !important;
  outline: none !important;
}
.swiper3d .swiper-slide-next:focus,
.swiper3d .swiper-slide-prev:focus{
  border: none !important;
  box-shadow: initial !important;
  outline: none !important;
}

.swiper3d .swiper-button-next:after,
.swiper3d .swiper-button-prev:after {
  content: '' !important;
}

.swiper3d .swiper-slide-prev {
  transform: translate3d(52rem, 0, -300px) !important;
}

.swiper3d .swiper-slide-next {
  transform: translate3d(-52rem, 0, -300px) !important;

}

.swiper3d .swiper-button-next {
  right: 18%;
}

.swiper3d .swiper-button-prev {
  left: 18%;
}

.swiper3d .swiper-pagination-bullet {
  border-radius: initial !important;
  background: #999999 !important;
  width: 2.5rem !important;
  height: 0.3rem !important;
}

.swiper3d .swiper-pagination-bullet-active {
  background-color: #fff !important;
}

/*头部箭头*/
.arrowhead {
  position: absolute;
  bottom: 6.9rem;
  left: 50%;
  width: 1.4rem;
  height: auto;
  display: block;
  animation: scalIcon 1s ease-out infinite;
  -o-animation: scalIcon 1s ease-out infinite;
  -webkit-animation: scalIcon 1s ease-out infinite;
  -moz-animation: scalIcon 1s ease-out infinite;
}

@-o-keyframes scalIcon {
  0% {
    transform: translateY(0);
  }

  25% {
    transform: translateY(1rem);
  }

  50% {
    transform: translateY(0);
  }

  75% {
    transform: translateY(0.2rem);
  }

  100% {
    transform: translateY(0);
  }
}

@-webkit-keyframes scalIcon {
  0% {
    transform: translateY(0);
  }

  25% {
    transform: translateY(1rem);
  }

  50% {
    transform: translateY(0);
  }

  75% {
    transform: translateY(0.2rem);
  }

  100% {
    transform: translateY(0);
  }
}

@-moz-keyframes scalIcon {
  0% {
    transform: translateY(0);
  }

  25% {
    transform: translateY(1rem);
  }

  50% {
    transform: translateY(0);
  }

  75% {
    transform: translateY(0.2rem);
  }

  100% {
    transform: translateY(0);
  }
}

@-ms-keyframes scalIcon {
  0% {
    transform: translateY(0);
  }

  25% {
    transform: translateY(1rem);
  }

  50% {
    transform: translateY(0);
  }

  75% {
    transform: translateY(0.2rem);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes scalIcon {
  0% {
    transform: translateY(0);
  }

  25% {
    transform: translateY(1rem);
  }

  50% {
    transform: translateY(0);
  }

  75% {
    transform: translateY(0.2rem);
  }

  100% {
    transform: translateY(0);
  }
}

/*头部背景图*/
.home-header {
  width: 100%;
  height: 100vh;
  background: url(../../resource/bg_home_font.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  position: relative;
  max-width: 1920px !important;
  margin: 0 auto;
  box-sizing: border-box;
  z-index: 2;
}

.home-header-fix {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 1920px;
  box-sizing: border-box;
  margin-top: -80vh;
}

#distance-vh {
  width: 100%;
  height: 200vh;
}


.home-header-nofix {
  position: relative;
  left: 0;
  bottom: 0;
  max-width: 1920px;
  margin: 0 auto;
  box-sizing: border-box;
  margin-top: -80vh;
}

.home-bag-display {
  width: 100%;
  height: 100vh;
  background: url(../../resource/bg_home_font.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}

.pc-cooperation-font {
  display: block;
  margin: 0 auto;
  width: 87.5rem;
  height: auto;
  object-fit: contain;
  margin-bottom: 12rem;
}

.app-cooperation-font {
  display: none;
}

/*新闻的swiper*/
.home-content .home-swiper {
  width: 100%;
  overflow: hidden;
  background: #1D3522;
}

.home-content .home-swiper .pc-swiper {
  width: 140%;
  position: relative;
  left: -20%;
  margin-top: 2rem;
  position: relative;
}

.home-content .home-swiper .pc-swiper .swiper {
  width: 100%;
  height: 48rem;
}

.home-content .home-swiper .pc-swiper .swiper .swiper-slide {
  width: 58.5rem;
  height: 31rem;
  position: relative;
}

.home-content .home-swiper .pc-swiper .swiper .swiper-slide img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.home-content .home-swiper .pc-swiper .swiper .swiper-slide .title {
  /* margin-top: 5.4rem;
  margin-bottom: 1.875rem; */
  font-size: 2rem;
  font-weight: 400;
  color: #FFFFFF;
  width: 100%;
  text-align: center;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  top: 37rem;
}

.home-content .home-swiper .pc-swiper .swiper .swiper-slide .desc {
  font-weight: 400;
  color: #54F0B8;
  font-size: 1.125rem;
  width: 100%;
  text-align: center;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  top: 42rem;
}

.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0 !important;
  top: 33rem !important;
}

.pc-swiper .swiper-pagination-bullet {
  border-radius: initial !important;
  width: 3.75rem !important;
  height: 0.125rem !important;
  background: rgba(255, 255, 255, 0.56) !important;
}

.pc-swiper .swiper-button-prev {
  left: 17% !important;
  top: 30% !important;
}

.pc-swiper .swiper-button-prev::after {
  content: '' !important;
}

.pc-swiper .swiper-button-next {
  right: 17% !important;
  top: 30% !important;
}

.pc-swiper .swiper-button-next::after {
  content: '' !important;
}

.pc-swiper-opcity {
  opacity: 0.5;
}

.app-swiper {
  display: none;
}

/*关于景合*/
.home-about-us {
  padding: 7.5rem 0 4.3rem 0;

}

.hpme-about-us-box {
  padding: 0 21.5rem;
  box-sizing: border-box;
  background: #fff;
}

.hpme-about-us-box .content {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: #fff;
}

.hpme-about-us-box .content .title {
  color: #666666;
  font-weight: 500;
  font-size: 1.125rem;
  width: 100%;
  text-align: center;
}

.hpme-about-us-box .content .label {
  color: #666666;
  font-weight: 500;
  font-size: 1.125rem;
  margin-top: 0.8rem;
  width: 100%;
  text-align: center;
}

.hpme-about-us-box .content .roll {
  display: flex;
  justify-content: space-between;
}

.hpme-about-us-box .content .roll .box .desc {
  font-weight: 550;
  font-size: 1.325rem;
  margin-top: 1rem;
}

.hpme-about-us-box .content .roll .box .num {
  cursor: pointer;
  font-size: 3.5rem;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: 500;
  color: #ff9c47;
  line-height: 4.025rem;
  background: linear-gradient(97deg, #ed6700 0%, #fd9f50 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin-top: 4.3rem;
}

.hpme-about-us-box .content .more {
  width: 7.5rem;
  height: 2.5rem;
  border-radius: 4px;
  color: #333333;
  text-align: center;
  line-height: 2.5rem;
  border: 1px solid #999999;
  margin: 0 auto;
  margin-top: 3rem;
  margin-bottom: 7.5rem;
  cursor: pointer;
}


/*视差*/
.home-parallax {
  width: 100%;
  height: 65rem;
  overflow: hidden;
  position: relative;
}

.homw-parallax-box {
  position: relative;
  width: 150%;
  transform: rotate(15deg) translateX(-25%) translateY(-11%);
}

.homw-parallax-box div {
  display: flex;
  justify-content: space-between;
  transition: all 1.5s ease-out;
  position: relative;
}

.homw-parallax-box div img {
  width: 70rem;
  height: 28.7rem;
  display: block;
  object-fit: cover;
  margin: 2rem;
}


/*字体变色*/
.home-font-scoll {
  width: 100%;
  height: 110rem;
  background: #1E2C24;
  padding: 9rem 9rem;
  box-sizing: border-box;
}

.home-font-scoll .pc-font-scoll {
  width: 100%;
  height: 100%;
}

.home-font-scoll .pc-font-scoll .box .title {
  font-size: 1.6rem;
  font-weight: 400;
  color: #FFFFFF;
  margin-bottom: 6.9rem;
}

.home-font-scoll .pc-font-scoll .box .font-block-none {
  position: relative;
  font-size: 4.4rem;
  color: #d9d3d36e;
  overflow: hidden;
  width: 100%;
  height: 10rem;
}

.home-font-scoll .pc-font-scoll .box .font-block-none .font-none {
  white-space: nowrap;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
}

.home-font-scoll .pc-font-scoll .box .font-block-none .font-block {
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 0;
  color: #fff;
  white-space: nowrap;
}

.home-font-scoll .pc-font-scoll .box .last-font {
  margin-top: 6rem;
}

.app-font-scoll {
  display: none;
}


/*技术优势*/
.home-tec-advantage {
  width: 100%;
  height: 62rem;
  position: relative;
  background-image: url(../../resource//icon_data_base_earth.png),
    url(../../resource/bg_base_data.png), url(../../resource/icon_base_data_location.png);
  background-size: 11.5rem 11.5rem, contain, cover;
  background-repeat: no-repeat, no-repeat, no-repeat;
  overflow: hidden;
  background-position: 6.75rem 10.3rem, 0 17rem;
  padding-bottom: 5.3rem;
}

.home-tec-advantage img {
  width: 100%;
  height: 100%;
  display: block;
}

.home-tec-advantage .title {
  width: 3.3rem;
  height: 12rem;
  font-weight: 600;
  color: #333333;
  font-size: 2.6rem;
  writing-mode: vertical-rl;
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: bt-rl;
  display: block;
  letter-spacing: 0.3rem;
  position: absolute;
  top: 10.3rem;
  left: 60.1rem;
  transform: translateX(-50%);
}

.icon_data_base01 {
  width: 15.8rem;
  height: 15.8rem;
  display: block;
  position: absolute;
  left: 25rem;
  top: 10.1rem;
  transition: all 1s ease-in-out;
  transform-style: preserve-3d;
  z-index: 2;
}

.icon_data_base02 {
  width: 15.8rem;
  height: 15.8rem;
  display: block;
  position: absolute;
  top: 23rem;
  left: 33.4rem;
  transition: all 1s ease-in-out;
  z-index: 3;
}

.icon_data_base03 {
  width: 13.7rem;
  height: 13.7rem;
  display: block;
  position: absolute;
  top: 31.2rem;
  left: 45.7rem;
  transition: all 1s ease-in-out;
  z-index: 2;
}

.icon_data_base04 {
  width: 15.8rem;
  height: 15.8rem;
  display: block;
  position: absolute;
  top: 34.9rem;
  left: 60.1rem;
  transition: all 1s ease-in-out;
  z-index: 2;
}

.icon_data_base05 {
  width: 14.4rem;
  height: 14.4rem;
  display: block;
  position: absolute;
  top: 23.1rem;
  left: 69.6rem;
  transition: all 1s ease-in-out;
  z-index: 1;
}

.icon_data_base06 {
  width: 18.25rem;
  height: 18.25rem;
  display: block;
  position: absolute;
  top: 9.7rem;
  left: 76.8rem;
  transition: all 1s ease-in-out;
  z-index: 2;
}

/*业务合作模式*/
/* .home-busi-m{
  margin-top: -5rem;
} */
.business-mian-business {
  background: #fff;
  padding: 0 22.5rem;
  width: 100%;
  height: 40rem;
  background-size: cover;
  background-color: #fff;
}

.business-mian-business .container {
  max-width: 1920px !important;
}

.business-mian-business .business-main-box {
  width: 100%;
  height: 8.6rem;
  box-shadow: 0px 0.125rem 1.5rem 0px rgba(68, 62, 79, 0.12);
  border-radius: 0.75rem;
  display: flex;
  align-items: center;
  padding: 3.3rem 0 3.3rem 2.2rem;
  margin-top: 3.6rem;
}

.bag-main-box {
  background: url(../../resource/bg_base_data01.png) no-repeat;
  background-position: 93% 50%;
  background-size: 4.1rem 3.3rem;
  transition: all .3s ease-in;
  transition-delay: .3s;
}

.bag-main-box2 {
  background: url(../../resource/bg_base_data02.png) no-repeat;

  background-position: 93% 50%;
  background-size: 4.1rem 3.3rem;
  transition: all .3s ease-in;
  transition-delay: .3s;
}

.bag-main-box3 {
  background: url(../../resource/bg_base_data03.png) no-repeat;

  background-position: 93% 50%;
  background-size: 4.1rem 3.3rem;
  transition: all .4s ease-in;
  transition-delay: .4s;
}

.bag-main-box4 {
  background: url(../../resource/bg_base_data04.png) no-repeat;

  background-position: 93% 50%;
  background-size: 4.1rem 3.3rem;
  transition: all .4s ease-in;
  transition-delay: .4s;
}

.bag-main-box5 {
  background: url(../../resource/bg_base_data05.png) no-repeat;
  background-position: 93% 50%;
  background-size: 4.1rem 3.3rem;
}

.bag-main-box6 {
  background-position: 93% 50%;
  background-size: 4.1rem 3.3rem;
  background: url(../../resource/bg_base_data06.png) no-repeat;
}

.bag-main-box7 {
  background-image: url(../../resource/bg_left_base_data.png),
    url(../../resource/bg_left_base_data.png),
    url(../../resource/bg_base_data05.png);
  background-repeat: no-repeat, no-repeat;
  background-position: 44% -100%, 75% 0, 96.5% 50%;
  background-size: 7rem 7rem, 8.6rem 8.6rem, 4.1rem 3.3rem;
  transition: all .5s ease-in;
  transition-delay: .5s;
}

.business-mian-business .business-main-box .icon {
  width: 1.69rem;
  height: 1.69rem;
}

.business-mian-business .business-main-box .desc {
  font-weight: 600;
  font-size: 1.5rem;
  margin-left: 0.5rem;
  color: #00A862;
}

.business-mian-business .business-main-box .icon img {
  width: 1.69rem;
}

/*景合亿家的轮播*/
.home-swiper-jinghe-app {
  /* display: none; */
  width: 100%;
  height: 100%;
  padding: 0 22.5rem;
  box-sizing: border-box;
  background: #1E2C24;
  margin-top: 4.8rem;
}

.home-swiper-jinghe-app .swiper-add-box {
  box-sizing: border-box;
  background: url(../../resource/jinghe-swiper-font.jpg) no-repeat;
  background-size: cover;
}

.home-swiper-jinghe-app img {
  width: 101% !important;
}

.home-swiper-jinghe {
  width: 100%;
  height: 100%;
  padding: 0 22.5rem;
  box-sizing: border-box;
  background: #1E2C24;
  margin-top: 8.75rem;
}

.home-swiper-jinghe .box {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.home-swiper-jinghe .box .swiper {
  width: 101%;
  height: 28rem;
  box-sizing: border-box;
  color: #fff;
  background: url(../../resource/jinghe-swiper-font.png) no-repeat;
  background-size: cover;
  /* animation: jinghe 5s ease-out infinite;
  -o-animation: jinghe 5s ease-out infinite;
  -webkit-animation: jinghe 5s ease-out infinite;
  -moz-animation: jinghe 5s ease-out infinite; */
}

@-o-keyframes jinghe {
  0% {
    background: url(../../resource/jinghe-swiper-font.png) no-repeat;
  }

  100% {
    background: url(../../resource/jinghe-swiper-font02.png) no-repeat;
  }
}

@-webkit-keyframes jinghe {
  0% {
    background: url(../../resource/jinghe-swiper-font.png) no-repeat;
  }

  100% {
    background: url(../../resource/jinghe-swiper-font02.png) no-repeat;
  }
}

@-moz-keyframes jinghe {
  0% {
    background: url(../../resource/jinghe-swiper-font.png) no-repeat;
  }

  100% {
    background: url(../../resource/jinghe-swiper-font02.png) no-repeat;
  }
}

@-ms-keyframes jinghe {
  0% {
    background: url(../../resource/jinghe-swiper-font.png) no-repeat;
  }

  100% {
    background: url(../../resource/jinghe-swiper-font02.png) no-repeat;
  }
}

@keyframes jinghe {
  0% {
    background: url(../../resource/jinghe-swiper-font.png) no-repeat;
  }

  100% {
    background: url(../../resource/jinghe-swiper-font02.png) no-repeat;
  }
}

.home-swiper-jinghe .box .swiper .IMG {
  width: 101%;
  height: 28rem;
}

.home-swiper-jinghe .box .swiper .IMG img {
  width: 100%;
  height: 28rem;
  display: block;
  object-fit: cover;
}

/*部分合作案例*/
.pc-case-carousel {
  display: block;
  margin-top: 4.3rem;
  overflow: hidden;
  padding: 0 16rem;
  box-sizing: border-box;
}

.coo-appmySwiper {
  display: none;
}

.some-cooperation-case {
  margin-top: 5.3rem;
}

.case_carousel {
  margin: 0 14.375rem;
  margin-top: 3.4rem;
  position: relative;
}

.case_carousel .left_arrow {
  width: 1.3rem;
  height: 2.3rem;
  position: absolute;
  left: 14.7rem;
  top: 19rem;
  z-index: 5;
  cursor: pointer;
}

.el-carousel__arrow {
  /* display: none !important; */
  background-color: initial !important;
}

.el-carousel__arrow .el-icon {
  width: 5rem !important;
  height: 5rem !important;
  line-height: 5rem !important;
}

.el-carousel__arrow .el-icon svg {
  width: 5rem !important;
  height: 5rem !important;
}

.el-carousel__arrow--right {
  right: 14rem !important;
}

.el-carousel__arrow--left {
  left: 14rem !important;
}

.case_carousel .el-carousel__container {
  height: 38rem !important;
}

.el-carousel__indicator>.el-carousel__button {
  width: 2.5rem !important;
  height: 0.3rem !important;
}

.el-carousel__indicators--outside button {
  background: #fff !important;
}

.case_carousel .el-carousel__indicators--outside {
  position: absolute !important;
  bottom: 4.1rem !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  height: 0 !important;
}

.case_carousel .el-carousel__item--card.is-active {
  width: 75% !important;
  transform: translateX(12rem) !important;
}

.case_carousel .right_arrow {
  width: 1.3rem;
  height: 2.3rem;
  position: absolute;
  right: 14.7rem;
  top: 19rem;
  z-index: 5;
  cursor: pointer;
}

.case_carousel_box {
  width: 100%;
  height: 100%;
}

.case_carousel_box a img {
  width: 100%;
  height: 100%;
}

.case_carousel_box .desc {
  position: absolute;
  color: #fff;
  z-index: 999;
  bottom: 2.25rem;
  left: 2.9rem;
}

.case_carousel_box .label {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.case_carousel_box .more {
  display: flex;
  align-items: center;
}

.case_carousel_box .more span img {
  margin-top: -0.2rem;
  margin-left: 0.5rem;
}

.el-carousel__mask {
  opacity: 0.7 !important;
}

/*更多案例*/
.coo-more-btn {
  width: 7.5rem;
  height: 2.5rem;
  border-radius: 4px;
  color: #333333;
  text-align: center;
  line-height: 2.5rem;
  border: 1px solid #999999;
  margin: 0 auto;
  margin-top: 3rem;
  cursor: pointer;
}

@media screen and (min-width:1009px) and (max-width:1011px) {

  /*头部背景图*/
  .pc-cooperation-font {
    display: none;
  }

  .coo-operation-mode .IMG .pc-coo-mode {
    display: none;
  }

  .home-header {
    width: 100%;
    height: 100vh;
    background: url(../../resource/bg_home_font.jpg) no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    position: relative;
  }

  .app-cooperation-font {
    width: 70.75rem;
    height: auto;
    display: block;
    margin: auto;
  }

  /*新闻swiper*/
  .pc-swiper {
    display: none;
  }

  .app-swiper {
    display: block;
    padding: 8.125rem 7.6rem 0 7.6rem;
    box-sizing: border-box;
    height: 120rem;
  }

  .app-swiper .carousel-inner {
    height: 90rem !important;
  }

  .app-swiper .carousel-indicators {
    top: 65.6rem !important;
    bottom: initial !important;
  }

  .app-swiper .carousel-caption {
    bottom: initial !important;
    top: 75.6rem !important;
    left: 0 !important;
    right: 0 !important;
    padding: 0 !important;
    margin: 0 !important;

  }

  .app-swiper .carousel-indicators [data-bs-target] {
    border-top: initial !important;
    border: none !important;
    outline: none !important;
  }

  .app-swiper .carousel-caption h5 {
    font-size: 1.25rem;
    font-weight: 300;
    color: #FFFFFF;
    width: 100%;
    text-align: start;
  }

  .app-swiper .carousel-caption p {
    font-size: 0.875rem;
    font-weight: 400;
    color: #54F0B8;
    width: 100%;
    text-align: start;
    margin-top: 1.2rem;
  }

  .app-swiper .more {
    width: 100%;
    height: 2.6rem;
    line-height: 2.6rem;
    font-size: 0.875rem;
    border: 1px solid #FFFFFF;
    font-weight: 300;
    color: #FFFFFF;
    text-align: center;
  }

  /*新闻swiper*/
  /* .pc-swiper {
    display: none;
  }

  .app-swiper {
    display: block;
    padding: 7.125rem 1.6rem 0 1.6rem;
    box-sizing: border-box;
    height: 55rem;
  }

  .app-swiper .carousel-inner {
    height: 40rem !important;

  }

  .app-swiper .carousel-indicators {
    top: 27.6rem !important;
    bottom: initial !important;
  }

  .app-swiper .carousel-caption {
    bottom: initial !important;
    top: 31.6rem !important;
    left: 0 !important;
    right: 0 !important;
    padding: 0 !important;
    margin: 0 !important;

  }

  .app-swiper .carousel-indicators [data-bs-target] {
    border-top: initial !important;
    border: none !important;
    outline: none !important;
  }

  .app-swiper .carousel-caption h5 {
    font-size: 1.25rem;
    font-weight: 300;
    color: #FFFFFF;
    width: 100%;
    text-align: start;
  }

  .app-swiper .carousel-caption p {
    font-size: 0.875rem;
    font-weight: 400;
    color: #54F0B8;
    width: 100%;
    text-align: start;
    margin-top: 1.2rem;
  }

  .app-swiper .more {
    width: 100%;
    height: 2.6rem;
    line-height: 2.6rem;
    font-size: 0.875rem;
    border: 1px solid #FFFFFF;
    font-weight: 300;
    color: #FFFFFF;
    text-align: center;
  } */

  /*关于景合*/
  .home-about-us {
    padding: 5.3rem 0 2.3rem 0;

  }

  .hpme-about-us-box {
    padding: 0 9.6rem;
    box-sizing: border-box;
    background: #fff;
  }

  .hpme-about-us-box .content {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background: #fff;
  }

  .hpme-about-us-box .content .title {
    color: #666666;
    font-weight: 500;
    font-size: 0.75rem;
    width: 100%;
    text-align: start;
  }

  .hpme-about-us-box .content .label {
    color: #666666;
    font-weight: 500;
    font-size: 0.75rem;
    margin-top: 0.8rem;
    width: 100%;
    text-align: start;
  }

  .hpme-about-us-box .content .roll {
    display: flex;
    justify-content: space-between;
  }

  .hpme-about-us-box .content .roll .box .desc {
    font-weight: bold;
    font-size: 0.6rem;
    margin-top: 1rem;
  }

  .hpme-about-us-box .content .roll .box .num {
    cursor: pointer;
    font-size: 1.5rem;
    font-family: DINAlternate-Bold, DINAlternate;
    font-weight: 500;
    color: #ff9c47;
    line-height: 4.025rem;
    background: linear-gradient(97deg, #ed6700 0%, #fd9f50 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin-top: 2.3rem;
  }

  .hpme-about-us-box .content .more {
    width: 7.5rem;
    height: 2.5rem;
    border-radius: 4px;
    color: #333333;
    text-align: center;
    line-height: 2.5rem;
    border: 1px solid #999999;
    margin: 0 auto;
    margin-top: 3rem;
    margin-bottom: 7.5rem;
    cursor: pointer;
  }

  /*业务合作模块*/
  .business-mian-business {
    background: #fff;
    padding: 0 10.12rem;
    box-sizing: border-box;
    height: 62rem;
  }

  .business-mian-business .container {
    max-width: 998px !important;
  }

  .business-mian-business .container .row {
    margin: 0;
  }

  .business-mian-business .business-main-box {
    width: 100%;
    box-shadow: 0px 0.125rem 1.5rem 0px rgba(68, 62, 79, 0.12);
    border-radius: 12px;
    display: flex;
    align-items: center;
    height: 10rem;
    margin-top: 0.05rem;
    padding: 2rem 0 2rem 1.3rem;
    box-sizing: border-box;
  }

  .business-mian-business .business-main-box .icon {
    width: 2rem;
    height: 2rem;
    box-sizing: border-box;
    margin-top: -0.2rem;
  }

  .business-mian-business .business-main-box .desc {
    font-size: 2rem;
    margin-left: 1.4rem;
  }

  .business-mian-business .business-main-box .icon img {
    width: 2rem;
    height: 2rem;
    vertical-align: initial !important;
  }

  .app-business-model {
    height: 0 !important;
  }

  .bag-main-box {
    background-size: 2rem 1.6rem;
  }

  .bag-main-box2 {
    background-size: 2rem 1.6rem;
  }

  .bag-main-box3 {
    background-size: 2rem 1.6rem;
  }

  .bag-main-box4 {
    background-size: 2rem 1.6rem;
  }

  .bag-main-box5 {
    background-size: 2rem 1.6rem;
  }

  .bag-main-box6 {
    background-size: 2rem 1.6rem !important;
    background-position: 93% 50%;
  }

  .bag-main-box7 {
    background: initial;
    background: url(../../resource/bg_base_data05.png) no-repeat !important;
    background-size: 2rem 1.6rem !important;
    background-position: 93% 50% !important;
  }

  /*景合亿家的轮播*/
  .home-swiper-jinghe {
    display: none !important;
    ;
  }

  .home-swiper-jinghe-app {
    display: block;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background: url(../../resource/jinghe-swiper-font.jpg) no-repeat;
    background-size: cover;
    padding: 0;
  }

  .home-swiper-jinghe-app img {
    width: 101% !important;
  }

  /* .home-swiper-jinghe-app .carousel-inner {
    width: 100%;
    height: 12rem;
    box-sizing: border-box;
  }

  .home-swiper-jinghe-app .carousel-inner .carousel-item {
    width: 100%;
    height: 12rem;
    box-sizing: border-box;
  }

  .home-swiper-jinghe-app .carousel-inner .carousel-item img {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    object-fit: cover;
  } */
}

@media screen and (min-width:993px) and (max-width:1008px) {

  /*头部背景图*/
  .pc-cooperation-font {
    display: none !important;
  }

  .coo-operation-mode .IMG .pc-coo-mode {
    display: none;
  }

  .home-header {
    width: 100%;
    height: 100vh;
    background: url(../../resource/bg_home_font.jpg) no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    position: relative;
  }

  .app-cooperation-font {
    width: 34.75rem;
    height: auto;
    display: block;
    margin: auto;
  }

  /*新闻swiper*/
  .pc-swiper {
    display: none;
  }

  .app-swiper {
    display: block;
    padding: 7.125rem 1.6rem 0 1.6rem;
    box-sizing: border-box;
    height: 45rem;
  }

  .app-swiper .carousel-inner {
    height: 27rem !important;
  }

  .app-swiper .carousel-indicators {
    top: 12.6rem !important;
    bottom: initial !important;
  }

  .app-swiper .carousel-caption {
    bottom: initial !important;
    top: 14.6rem !important;
    left: 0 !important;
    right: 0 !important;
    padding: 0 !important;
    margin: 0 !important;

  }

  .app-swiper .carousel-indicators [data-bs-target] {
    border-top: initial !important;
    border: none !important;
    outline: none !important;
  }

  .app-swiper .carousel-caption h5 {
    font-size: 1.25rem;
    font-weight: 300;
    color: #FFFFFF;
    width: 100%;
    text-align: start;
  }

  .app-swiper .carousel-caption p {
    font-size: 0.875rem;
    font-weight: 400;
    color: #54F0B8;
    width: 100%;
    text-align: start;
    margin-top: 1.2rem;
  }

  .app-swiper .more {
    width: 100%;
    height: 2.6rem;
    line-height: 2.6rem;
    font-size: 0.875rem;
    border: 1px solid #FFFFFF;
    font-weight: 300;
    color: #FFFFFF;
    text-align: center;
  }

  /*新闻swiper*/
  .pc-swiper {
    display: none;
  }

  .app-swiper {
    display: block;
    padding: 7.125rem 1.6rem 0 1.6rem;
    box-sizing: border-box;
    height: 55rem;
  }

  .app-swiper .carousel-inner {
    height: 40rem !important;

  }

  .app-swiper .carousel-indicators {
    top: 27.6rem !important;
    bottom: initial !important;
  }

  .app-swiper .carousel-caption {
    bottom: initial !important;
    top: 31.6rem !important;
    left: 0 !important;
    right: 0 !important;
    padding: 0 !important;
    margin: 0 !important;

  }

  .app-swiper .carousel-indicators [data-bs-target] {
    border-top: initial !important;
    border: none !important;
    outline: none !important;
  }

  .app-swiper .carousel-caption h5 {
    font-size: 1.25rem;
    font-weight: 300;
    color: #FFFFFF;
    width: 100%;
    text-align: start;
  }

  .app-swiper .carousel-caption p {
    font-size: 0.875rem;
    font-weight: 400;
    color: #54F0B8;
    width: 100%;
    text-align: start;
    margin-top: 1.2rem;
  }

  .app-swiper .more {
    width: 100%;
    height: 2.6rem;
    line-height: 2.6rem;
    font-size: 0.875rem;
    border: 1px solid #FFFFFF;
    font-weight: 300;
    color: #FFFFFF;
    text-align: center;
  }

  /*关于景合*/
  .home-about-us {
    padding: 5.3rem 0 2.3rem 0;

  }

  .hpme-about-us-box {
    padding: 0 1.6rem;
    box-sizing: border-box;
    background: #fff;
  }

  .hpme-about-us-box .content {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background: #fff;
  }

  .hpme-about-us-box .content .title {
    color: #666666;
    font-weight: 500;
    font-size: 0.75rem;
    width: 100%;
    text-align: start;
  }

  .hpme-about-us-box .content .label {
    color: #666666;
    font-weight: 500;
    font-size: 0.75rem;
    margin-top: 0.8rem;
    width: 100%;
    text-align: start;
  }

  .hpme-about-us-box .content .roll {
    display: flex;
    justify-content: space-between;
  }

  .hpme-about-us-box .content .roll .box .desc {
    font-weight: bold;
    font-size: 0.6rem;

  }

  .hpme-about-us-box .content .roll .box .num {
    cursor: pointer;
    font-size: 1.5rem;
    font-family: DINAlternate-Bold, DINAlternate;
    font-weight: 500;
    color: #ff9c47;
    line-height: 4.025rem;
    background: linear-gradient(97deg, #ed6700 0%, #fd9f50 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin-top: 2.3rem;
  }

  .hpme-about-us-box .content .more {
    /* width: 100%;
    height: 2.75rem;
    border: 1px solid #999999;
    border-radius: initial;
    margin: auto;
    text-align: center;
    line-height: 2.75rem;
    margin-top: 2.4rem;
    margin-bottom: 7.5rem;
    font-size: 1rem;
    cursor: pointer;
    color: #333333;
    background: #fff; */
    width: 7.5rem;
    height: 2.5rem;
    border-radius: 4px;
    color: #333333;
    text-align: center;
    line-height: 2.5rem;
    border: 1px solid #999999;
    margin: 0 auto;
    margin-top: 3rem;
    margin-bottom: 7.5rem;
    cursor: pointer;
  }

  /*数字滚动*/
  .pc-font-scoll {
    display: none !important;
  }

  .app-font-scoll {
    display: block !important;
  }

  .home-font-scoll {
    width: 100%;
    height: 95rem;
    padding: 6.25rem 6.625rem 0 6.625rem;
    box-sizing: border-box;
  }

  .home-font-scoll .app-font-scoll {
    width: 100%;
    height: 100%;
  }

  .home-font-scoll .app-font-scoll .box .title {
    font-weight: 400;
    color: #FFFFFF;
    font-size: 0.875rem;
    margin-bottom: 2rem;
  }

  .home-font-scoll .app-font-scoll .box .font-block-none {
    position: relative;
    font-size: 3.5rem;
    overflow: hidden;
    width: 100%;
    height: 6rem;

  }

  .home-font-scoll .app-font-scoll .box .font-block-none .font-none {
    white-space: nowrap;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    color: #fff;
    width: 100%;
    opacity: 0.5;
  }

  .home-font-scoll .app-font-scoll .box .font-block-none .font-block {
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    width: 0;
    color: #fff;
    white-space: nowrap;
    opacity: 1;
  }

  .home-font-scoll .app-font-scoll .box .last-font {
    margin-top: 3rem;
  }

  /*技术优势*/
  .home-tec-advantage {
    width: 100%;
    height: 25rem;
    position: relative;
    background-image: url(../../resource//icon_data_base_earth.png),
      url(../../resource/bg_base_data.png), url(../../resource/icon_base_data_location.png);
    background-size: 3rem 3rem, contain, cover;
    background-repeat: no-repeat, no-repeat, no-repeat;
    overflow: hidden;
    background-position: 0.375rem 4.3rem, 0 10rem;
    padding-bottom: 5.3rem;
  }

  .home-tec-advantage img {
    width: 100%;
    height: 100%;
    display: block;
  }

  .home-tec-advantage .title {
    width: 3.3rem;
    height: 12rem;
    font-weight: 600;
    color: #333333;
    font-size: 1.25rem;
    writing-mode: vertical-rl;
    -webkit-writing-mode: vertical-rl;
    -ms-writing-mode: bt-rl;
    display: block;
    letter-spacing: 0.3rem;
    position: absolute;
    top: 4.3rem;
    left: 29rem;
    transform: translateX(-50%);
  }

  .icon_data_base01 {
    width: 4.6rem;
    height: 4.6rem;
    display: block;
    position: absolute;
    left: 18.3rem;
    top: 6.6rem;
    transition: all 1s ease-in-out;
    transform-style: preserve-3d;
    z-index: 2;
  }

  .icon_data_base02 {
    width: 4.6rem;
    height: 4.6rem;
    display: block;
    position: absolute;
    top: 10.4rem;
    left: 21rem;
    transition: all 1s ease-in-out;
    z-index: 3;
  }

  .icon_data_base03 {
    width: 4.1rem;
    height: 4.1rem;
    display: block;
    position: absolute;
    top: 13rem;
    left: 24.5rem;
    transition: all 1s ease-in-out;
    z-index: 2;
  }

  .icon_data_base04 {
    width: 4.6rem;
    height: 4.6rem;
    display: block;
    position: absolute;
    top: 14rem;
    left: 29rem;
    transition: all 1s ease-in-out;
    z-index: 2;
  }

  .icon_data_base05 {
    width: 4.25rem;
    height: 4.25rem;
    display: block;
    position: absolute;
    top: 10.5rem;
    left: 31.6rem;
    transition: all 1s ease-in-out;
    z-index: 1;
  }

  .icon_data_base06 {
    width: 5.4rem;
    height: 5.4rem;
    display: block;
    position: absolute;
    top: 6.5rem;
    left: 33.75rem;
    transition: all 1s ease-in-out;
    z-index: 2;
  }

  /*业务合作模块*/
  .business-mian-business {
    background: #fff;
    padding: 0 1.12rem;
    box-sizing: border-box;

  }

  .business-mian-business .container {
    max-width: 375px !important;
  }

  .business-mian-business .container .row {
    margin: 0;
  }

  .business-mian-business .business-main-box {
    width: 100%;
    box-shadow: 0px 0.125rem 1.5rem 0px rgba(68, 62, 79, 0.12);
    border-radius: 12px;
    display: flex;
    align-items: center;
    height: 5rem;
    margin-top: 0.05rem;
    padding: 2rem 0 2rem 1.3rem;
    box-sizing: border-box;
  }

  .business-mian-business .business-main-box .icon {
    width: 1rem;
    height: 1rem;
    box-sizing: border-box;
    margin-top: -0.2rem;
  }

  .business-mian-business .business-main-box .desc {
    font-size: 1rem;
    margin-left: 0.4rem;
  }

  .business-mian-business .business-main-box .icon img {
    width: 1rem;
    height: 1rem;
    vertical-align: initial !important;
  }

  .app-business-model {
    height: 0 !important;
  }

  .bag-main-box {
    background-size: 2rem 1.6rem;
  }

  .bag-main-box2 {
    background-size: 2rem 1.6rem;
  }

  .bag-main-box3 {
    background-size: 2rem 1.6rem;
  }

  .bag-main-box4 {
    background-size: 2rem 1.6rem;
  }

  .bag-main-box5 {
    background-size: 2rem 1.6rem;
  }

  .bag-main-box6 {
    background-size: 2rem 1.6rem !important;
    background-position: 93% 50%;
  }

  .bag-main-box7 {
    background: initial;
    background: url(../../resource/bg_base_data05.png) no-repeat !important;
    background-size: 2rem 1.6rem !important;
    background-position: 93% 50% !important;
  }

  /*景合亿家的轮播*/
  .home-swiper-jinghe {
    display: none !important;
    ;
  }

  .home-swiper-jinghe-app {
    display: block;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background: url(../../resource/jinghe-swiper-font.jpg) no-repeat;
    background-size: cover;
    padding: 0;
  }

  .home-swiper-jinghe-app img {
    width: 101% !important;
  }

  /* .home-swiper-jinghe-app .carousel-inner {
    width: 100%;
    height: 12rem;
    box-sizing: border-box;
  }

  .home-swiper-jinghe-app .carousel-inner .carousel-item {
    width: 100%;
    height: 12rem;
    box-sizing: border-box;
  }

  .home-swiper-jinghe-app .carousel-inner .carousel-item img {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    object-fit: cover;
  } */

  /*部分合作案例*/
  /*部分合作案例*/
  .pc-case-carousel {
    display: none;
  }

  .coo-appmySwiper {
    display: block;
    margin-top: 1.2rem;
    position: relative;
  }

  .coo-appmySwiper .swiper-slide .box {
    width: 100%;
    height: 12.7rem;
    position: relative;
  }

  .coo-appmySwiper .swiper-slide .box .IMG {
    width: 100%;
    height: 12.7rem;
  }

  .coo-appmySwiper .swiper-slide .box .IMG img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: contain;
  }

  .coo-appmySwiper .swiper-slide .box .desc {
    position: absolute;
    bottom: 0.6rem;
    left: 1.5rem;
    z-index: 2;
    color: #fff;
    font-size: 0.75rem;
  }

  .coo-appmySwiper .swiper-button-next img,
  .coo-appmySwiper .swiper-button-prev img {
    width: 1rem !important;
    height: auto !important;
  }

  .coo-appmySwiper .swiper-button-next {
    right: 1.5rem !important;
    border: initial !important;
    border-color: inherit !important;
  }

  .coo-appmySwiper .swiper-button-prev {
    left: 1.5rem !important;
    border: none !important;
    outline: none !important;
  }

  .coo-appmySwiper .swiper-button-next::after {
    content: '' !important;
    border: initial !important;
    outline: none !important;
  }

  .coo-appmySwiper .swiper-button-prev::after {
    content: '' !important;
  }

  .coo-appmySwiper .swiper-slide .box .desc .more {
    display: flex;
    align-items: center;
  }

  .coo-appmySwiper .swiper-slide .box .desc .more img {
    width: 0.3rem;
    height: auto;
    margin-top: -0.1rem;
    margin-left: 0.3rem;
  }

  .coo-appmySwiper .swiper-horizontal>.swiper-pagination-bullets,
  .coo-appmySwiper .swiper-pagination-bullets.swiper-pagination-horizontal,
  .coo-appmySwiper .swiper-pagination-custom,
  .coo-appmySwiper .swiper-pagination-fraction {
    top: inherit !important;
    bottom: 0.6rem !important;
  }

  .coo-appmySwiper .swiper-pagination-bullet {
    border-radius: initial !important;
    width: 1rem !important;
    height: 0.2rem !important;
    background: #999999 !important;
  }

  .coo-appmySwiper .swiper-pagination-bullet-active {
    background: #fff !important;
    outline: none !important;
  }

}

@media screen and (min-width: 768px) and (max-width:992px) {

  /*头部背景图*/
  .pc-cooperation-font {
    display: none;
  }

  .coo-operation-mode .IMG .pc-coo-mode {
    display: none;
  }

  .home-header {
    width: 100%;
    height: 100vh;
    background: url(../../resource/bg_home_font.jpg) no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    position: relative;
  }

  .app-cooperation-font {
    width: 34.75rem;
    height: auto;
    display: block;
    margin: auto;
  }

  /*新闻swiper*/
  .pc-swiper {
    display: none;
  }

  .app-swiper {
    display: block;
    padding: 7.125rem 1.6rem 0 1.6rem;
    box-sizing: border-box;
    height: 55rem;
  }

  .app-swiper .carousel-inner {
    height: 40rem !important;

  }

  .app-swiper .carousel-indicators {
    top: 27.6rem !important;
    bottom: initial !important;
  }

  .app-swiper .carousel-caption {
    bottom: initial !important;
    top: 31.6rem !important;
    left: 0 !important;
    right: 0 !important;
    padding: 0 !important;
    margin: 0 !important;

  }

  .app-swiper .carousel-indicators [data-bs-target] {
    border-top: initial !important;
    border: none !important;
    outline: none !important;
  }

  .app-swiper .carousel-caption h5 {
    font-size: 1.25rem;
    font-weight: 300;
    color: #FFFFFF;
    width: 100%;
    text-align: start;
  }

  .app-swiper .carousel-caption p {
    font-size: 0.875rem;
    font-weight: 400;
    color: #54F0B8;
    width: 100%;
    text-align: start;
    margin-top: 1.2rem;
  }

  .app-swiper .more {
    width: 100%;
    height: 2.6rem;
    line-height: 2.6rem;
    font-size: 0.875rem;
    border: 1px solid #FFFFFF;
    font-weight: 300;
    color: #FFFFFF;
    text-align: center;
  }

  /*关于景合*/
  .home-about-us {
    padding: 5.3rem 0 2.3rem 0;

  }

  .hpme-about-us-box {
    padding: 0 1.6rem;
    box-sizing: border-box;
    background: #fff;
  }

  .hpme-about-us-box .content {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background: #fff;
  }

  .hpme-about-us-box .content .title {
    color: #666666;
    font-weight: 500;
    font-size: 0.75rem;
    width: 100%;
    text-align: start;
  }

  .hpme-about-us-box .content .label {
    color: #666666;
    font-weight: 500;
    font-size: 0.75rem;
    margin-top: 0.8rem;
    width: 100%;
    text-align: start;
  }

  .hpme-about-us-box .content .roll {
    display: flex;
    justify-content: space-between;
  }

  .hpme-about-us-box .content .roll .box .desc {
    font-weight: bold;
    font-size: 0.6rem;

  }

  .hpme-about-us-box .content .roll .box .num {
    cursor: pointer;
    font-size: 1.5rem;
    font-family: DINAlternate-Bold, DINAlternate;
    font-weight: 500;
    color: #ff9c47;
    line-height: 4.025rem;
    background: linear-gradient(97deg, #ed6700 0%, #fd9f50 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin-top: 2.3rem;
  }

  .hpme-about-us-box .content .more {
    /* width: 100%;
    height: 2.75rem;
    border: 1px solid #999999;
    border-radius: initial;
    margin: auto;
    text-align: center;
    line-height: 2.75rem;
    margin-top: 2.4rem;
    margin-bottom: 7.5rem;
    font-size: 1rem;
    cursor: pointer;
    color: #333333;
    background: #fff; */
    width: 7.5rem;
    height: 2.5rem;
    border-radius: 4px;
    color: #333333;
    text-align: center;
    line-height: 2.5rem;
    border: 1px solid #999999;
    margin: 0 auto;
    margin-top: 3rem;
    margin-bottom: 7.5rem;
    cursor: pointer;
  }

  /*数字滚动*/
  .pc-font-scoll {
    display: none !important;
  }

  .app-font-scoll {
    display: block !important;
  }

  .home-font-scoll {
    width: 100%;
    height: 74rem;
    padding: 6.25rem 3.625rem 0 3.625rem;
    box-sizing: border-box;
  }

  .home-font-scoll .app-font-scoll {
    width: 100%;
    height: 100%;
  }

  .home-font-scoll .app-font-scoll .box .title {
    font-weight: 400;
    color: #FFFFFF;
    font-size: 0.875rem;
    margin-bottom: 2rem;
  }

  .home-font-scoll .app-font-scoll .box .font-block-none {
    position: relative;
    font-size: 2.5rem;
    overflow: hidden;
    width: 100%;
    height: 4.5rem;

  }

  .home-font-scoll .app-font-scoll .box .font-block-none .font-none {
    white-space: nowrap;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    color: #fff;
    width: 100%;
    opacity: 0.5;
  }

  .home-font-scoll .app-font-scoll .box .font-block-none .font-block {
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    width: 0;
    color: #fff;
    white-space: nowrap;
    opacity: 1;
  }

  .home-font-scoll .app-font-scoll .box .last-font {
    margin-top: 3rem;
  }

  /*技术优势*/
  .home-tec-advantage {
    width: 100%;
    height: 25rem;
    position: relative;
    background-image: url(../../resource//icon_data_base_earth.png),
      url(../../resource/bg_base_data.png), url(../../resource/icon_base_data_location.png);
    background-size: 3rem 3rem, contain, cover;
    background-repeat: no-repeat, no-repeat, no-repeat;
    overflow: hidden;
    background-position: 0.375rem 4.3rem, 0 10rem;
    padding-bottom: 5.3rem;
  }

  .home-tec-advantage img {
    width: 100%;
    height: 100%;
    display: block;
  }

  .home-tec-advantage .title {
    width: 3.3rem;
    height: 12rem;
    font-weight: 600;
    color: #333333;
    font-size: 1.25rem;
    writing-mode: vertical-rl;
    -webkit-writing-mode: vertical-rl;
    -ms-writing-mode: bt-rl;
    display: block;
    letter-spacing: 0.3rem;
    position: absolute;
    top: 4.3rem;
    left: 29rem;
    transform: translateX(-50%);
  }

  .icon_data_base01 {
    width: 4.6rem;
    height: 4.6rem;
    display: block;
    position: absolute;
    left: 18.3rem;
    top: 6.6rem;
    transition: all 1s ease-in-out;
    transform-style: preserve-3d;
    z-index: 2;
  }

  .icon_data_base02 {
    width: 4.6rem;
    height: 4.6rem;
    display: block;
    position: absolute;
    top: 10.4rem;
    left: 21rem;
    transition: all 1s ease-in-out;
    z-index: 3;
  }

  .icon_data_base03 {
    width: 4.1rem;
    height: 4.1rem;
    display: block;
    position: absolute;
    top: 13rem;
    left: 24.5rem;
    transition: all 1s ease-in-out;
    z-index: 2;
  }

  .icon_data_base04 {
    width: 4.6rem;
    height: 4.6rem;
    display: block;
    position: absolute;
    top: 14rem;
    left: 29rem;
    transition: all 1s ease-in-out;
    z-index: 2;
  }

  .icon_data_base05 {
    width: 4.25rem;
    height: 4.25rem;
    display: block;
    position: absolute;
    top: 10.5rem;
    left: 31.6rem;
    transition: all 1s ease-in-out;
    z-index: 1;
  }

  .icon_data_base06 {
    width: 5.4rem;
    height: 5.4rem;
    display: block;
    position: absolute;
    top: 6.5rem;
    left: 33.75rem;
    transition: all 1s ease-in-out;
    z-index: 2;
  }

  /*业务合作模块*/
  .business-mian-business {
    background: #fff;
    padding: 0 3.12rem;
    box-sizing: border-box;

  }

  .business-mian-business .container {
    max-width: 998px !important;
  }

  .business-mian-business .container .row {
    margin: 0;
  }

  .business-mian-business .business-main-box {
    width: 100%;
    box-shadow: 0px 0.125rem 1.5rem 0px rgba(68, 62, 79, 0.12);
    border-radius: 12px;
    display: flex;
    align-items: center;
    height: 5rem;
    margin-top: 0.05rem;
    padding: 2rem 0 2rem 1.3rem;
    box-sizing: border-box;
  }

  .business-mian-business .business-main-box .icon {
    width: 1rem;
    height: 1rem;
    box-sizing: border-box;
    margin-top: -0.2rem;
  }

  .business-mian-business .business-main-box .desc {
    font-size: 1rem;
    margin-left: 0.4rem;
  }

  .business-mian-business .business-main-box .icon img {
    width: 1rem;
    height: 1rem;
    vertical-align: initial !important;
  }

  .app-business-model {
    height: 0 !important;
  }

  .bag-main-box {
    background-size: 2rem 1.6rem;
  }

  .bag-main-box2 {
    background-size: 2rem 1.6rem;
  }

  .bag-main-box3 {
    background-size: 2rem 1.6rem;
  }

  .bag-main-box4 {
    background-size: 2rem 1.6rem;
  }

  .bag-main-box5 {
    background-size: 2rem 1.6rem;
  }

  .bag-main-box6 {
    background-size: 2rem 1.6rem !important;
    background-position: 93% 50%;
  }

  .bag-main-box7 {
    background: initial;
    background: url(../../resource/bg_base_data05.png) no-repeat !important;
    background-size: 2rem 1.6rem !important;
    background-position: 93% 50% !important;
  }

  /*景合亿家的轮播*/
  .home-swiper-jinghe {
    display: none !important;
    ;
  }

  .home-swiper-jinghe-app {
    display: block;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background: url(../../resource/jinghe-swiper-font.jpg) no-repeat;
    background-size: cover;
    padding: 0;
  }

  .home-swiper-jinghe-app img {
    width: 101% !important;
  }

  /* 
  .home-swiper-jinghe-app .carousel-inner {
    width: 100%;
    height: 12rem;
    box-sizing: border-box;
  }

  .home-swiper-jinghe-app .carousel-inner .carousel-item {
    width: 100%;
    height: 12rem;
    box-sizing: border-box;
  }

  .home-swiper-jinghe-app .carousel-inner .carousel-item img {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    object-fit: cover;
  } */

  /*部分合作案例*/
  /*部分合作案例*/
  .pc-case-carousel {
    display: none;
  }

  .coo-appmySwiper {
    display: block;
    margin-top: 1.2rem;
    position: relative;
  }

  .coo-appmySwiper .swiper-slide .box {
    width: 100%;
    height: 12.7rem;
    position: relative;
  }

  .coo-appmySwiper .swiper-slide .box .IMG {
    width: 100%;
    height: 12.7rem;
  }

  .coo-appmySwiper .swiper-slide .box .IMG img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: contain;
  }

  .coo-appmySwiper .swiper-slide .box .desc {
    position: absolute;
    bottom: 0.6rem;
    left: 1.5rem;
    z-index: 2;
    color: #fff;
    font-size: 0.75rem;
  }

  .coo-appmySwiper .swiper-button-next img,
  .coo-appmySwiper .swiper-button-prev img {
    width: 1rem !important;
    height: auto !important;
  }

  .coo-appmySwiper .swiper-button-next {
    right: 1.5rem !important;
    border: initial !important;
    border-color: inherit !important;
    outline: none !important;
  }

  .coo-appmySwiper .swiper-button-prev {
    left: 1.5rem !important;
    border: none !important;
    outline: none !important;
  }

  .coo-appmySwiper .swiper-button-next::after {
    content: '' !important;
    border: initial !important;
  }

  .coo-appmySwiper .swiper-button-prev::after {
    content: '' !important;
  }

  .coo-appmySwiper .swiper-slide .box .desc .more {
    display: flex;
    align-items: center;
  }

  .coo-appmySwiper .swiper-slide .box .desc .more img {
    width: 0.3rem;
    height: auto;
    margin-top: -0.1rem;
    margin-left: 0.3rem;
  }

  .coo-appmySwiper .swiper-horizontal>.swiper-pagination-bullets,
  .coo-appmySwiper .swiper-pagination-bullets.swiper-pagination-horizontal,
  .coo-appmySwiper .swiper-pagination-custom,
  .coo-appmySwiper .swiper-pagination-fraction {
    top: inherit !important;
    bottom: 0.6rem !important;
  }

  .coo-appmySwiper .swiper-pagination-bullet {
    border-radius: initial !important;
    width: 1rem !important;
    height: 0.2rem !important;
    background: #999999 !important;
  }

  .coo-appmySwiper .swiper-pagination-bullet-active {
    background: #fff !important;
    outline: none !important;
  }

}

@media screen and (max-width: 767px) {
  .home-header-fix {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: 1920px;
    box-sizing: border-box;
    margin-top: -90vh;
  }
  
  #distance-vh {
    width: 100%;
    height: 200vh;
  }
  
  
  .home-header-nofix {
    position: relative;
    left: 0;
    bottom: 0;
    max-width: 1920px;
    margin: 0 auto;
    box-sizing: border-box;
    margin-top: -90vh;
  }
  .arrowhead {
    left: 47%;
  }

  /*头部背景图*/
  .pc-cooperation-font {
    display: none;
  }

  .coo-operation-mode .IMG .pc-coo-mode {
    display: none;
  }

  .home-header {
    width: 100%;
    height: 100vh;
    background: url(../../resource/bg_home_font.jpg) no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    position: relative;
  }

  .app-cooperation-font {
    width: 21.75rem;
    height: auto;
    display: block;
    margin: 0 auto;
    object-fit: contain;
    margin-top: -30%;
  }

  /*新闻swiper*/
  .pc-swiper {
    display: none;
  }

  .app-swiper {
    display: block;
    padding: 7.125rem 1.6rem 0 1.6rem;
    box-sizing: border-box;
    height: 45rem;
  }

  .app-swiper .carousel-inner {
    height: 27rem !important;
  }

  .app-swiper .carousel-indicators {
    top: 12.6rem !important;
    bottom: initial !important;
  }

  .app-swiper .carousel-caption {
    bottom: initial !important;
    top: 14.6rem !important;
    left: 0 !important;
    right: 0 !important;
    padding: 0 !important;
    margin: 0 !important;

  }

  .app-swiper .carousel-indicators [data-bs-target] {
    border-top: initial !important;
    border: none !important;
    outline: none !important;
  }

  .app-swiper .carousel-caption h5 {
    font-size: 1.25rem;
    font-weight: 300;
    color: #FFFFFF;
    width: 100%;
    text-align: start;
  }

  .app-swiper .carousel-caption p {
    font-size: 0.875rem;
    font-weight: 400;
    color: #54F0B8;
    width: 100%;
    text-align: start;
    margin-top: 1.2rem;
  }

  .app-swiper .more {
    width: 100%;
    height: 2.6rem;
    line-height: 2.6rem;
    font-size: 0.875rem;
    border: 1px solid #FFFFFF;
    font-weight: 300;
    color: #FFFFFF;
    text-align: center;
  }

  /*关于景合*/
  .home-about-us {
    padding: 5.3rem 0 2.3rem 0;

  }

  .hpme-about-us-box {
    padding: 0 1.6rem;
    box-sizing: border-box;
    background: #fff;
  }

  .hpme-about-us-box .content {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background: #fff;
  }

  .hpme-about-us-box .content .title {
    color: #666666;
    font-weight: 500;
    font-size: 0.75rem;
    width: 100%;
    text-align: start;
  }

  .hpme-about-us-box .content .label {
    color: #666666;
    font-weight: 500;
    font-size: 0.75rem;
    margin-top: 0.8rem;
    width: 100%;
    text-align: start;
  }

  .hpme-about-us-box .content .roll {
    display: flex;
    justify-content: space-between;
  }

  .hpme-about-us-box .content .roll .box .desc {
    font-weight: bold;
    font-size: 0.6rem;
    margin-top: 0;
  }

  .hpme-about-us-box .content .roll .box .num {
    cursor: pointer;
    font-size: 1.5rem;
    font-family: DINAlternate-Bold, DINAlternate;
    font-weight: 500;
    color: #ff9c47;
    line-height: 4.025rem;
    background: linear-gradient(97deg, #ed6700 0%, #fd9f50 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin-top: 2.3rem;
  }

  .hpme-about-us-box .content .more {
    width: 100%;
    height: 2.75rem;
    border: 1px solid #999999;
    border-radius: initial;
    margin: auto;
    text-align: center;
    line-height: 2.75rem;
    margin-top: 2.4rem;
    margin-bottom: 7.5rem;
    font-size: 1rem;
    cursor: pointer;
    color: #333333;
    background: #fff;
  }

  /*视差*/
  .home-parallax {
    width: 100%;
    height: 35rem;
    overflow: hidden;
    position: relative;
  }

  .homw-parallax-box {
    position: relative;
    width: 100%;
    transform: rotate(15deg) translateX(-50%) translateY(-11%);
  }

  .homw-parallax-box div {
    display: flex;
    justify-content: space-between;
    transition: all 1.5s ease-out;
    position: relative;
  }

  .homw-parallax-box div img {
    width: 20rem;
    height: 15.7rem;
    display: block;
    object-fit: cover;
    margin: 1rem;
  }

  /*数字滚动*/
  .pc-font-scoll {
    display: none !important;
  }

  .app-font-scoll {
    display: block !important;
  }

  .home-font-scoll {
    width: 100%;
    height: 50rem;
    padding: 6.25rem 1.625rem 0 1.625rem;
    box-sizing: border-box;
  }

  .home-font-scoll .app-font-scoll {
    width: 100%;
    height: 100%;
  }

  .home-font-scoll .app-font-scoll .box .title {
    font-weight: 400;
    color: #FFFFFF;
    font-size: 0.875rem;
    margin-bottom: 2rem;
  }

  .home-font-scoll .app-font-scoll .box .font-block-none {
    position: relative;
    font-size: 1.5rem;
    overflow: hidden;
    width: 100%;
    height: 2.5rem;

  }

  .home-font-scoll .app-font-scoll .box .font-block-none .font-none {
    white-space: nowrap;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    color: #fff;
    width: 100%;
    opacity: 0.5;
  }

  .home-font-scoll .app-font-scoll .box .font-block-none .font-block {
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    width: 0;
    color: #fff;
    white-space: nowrap;
    opacity: 1;
  }

  .home-font-scoll .app-font-scoll .box .last-font {
    margin-top: 3rem;
  }


  /*技术优势*/
  .home-tec-advantage {
    width: 100%;
    height: 22rem;
    position: relative;
    background-image: url(../../resource//icon_data_base_earth.png),
      url(../../resource/bg_base_data.png), url(../../resource/icon_base_data_location.png);
    background-size: 3rem 3rem, contain, cover;
    background-repeat: no-repeat, no-repeat, no-repeat;
    overflow: hidden;
    background-position: 0.375rem 4.3rem, 0 13rem;
    padding-bottom: 5.3rem;
  }

  .home-tec-advantage img {
    width: 100%;
    height: 100%;
    display: block;
  }

  .home-tec-advantage .title {
    width: 3.3rem;
    height: 12rem;
    font-weight: bold;
    color: #333333;
    font-size: 1.25rem;
    writing-mode: vertical-rl;
    -webkit-writing-mode: vertical-rl;
    -ms-writing-mode: bt-rl;
    display: block;
    letter-spacing: 0.3rem;
    position: absolute;
    top: 4.3rem;
    left: 11.2rem;
    transform: translateX(-50%);
  }

  .icon_data_base01 {
    width: 4.6rem;
    height: 4.6rem;
    display: block;
    position: absolute;
    left: 1.3rem;
    top: 6.6rem;
    transition: all 1s ease-in-out;
    transform-style: preserve-3d;
    z-index: 2;
  }

  .icon_data_base02 {
    width: 4.6rem;
    height: 4.6rem;
    display: block;
    position: absolute;
    top: 10.4rem;
    left: 4rem;
    transition: all 1s ease-in-out;
    z-index: 3;
  }

  .icon_data_base03 {
    width: 4.1rem;
    height: 4.1rem;
    display: block;
    position: absolute;
    top: 13rem;
    left: 7.5rem;
    transition: all 1s ease-in-out;
    z-index: 2;
  }

  .icon_data_base04 {
    width: 4.6rem;
    height: 4.6rem;
    display: block;
    position: absolute;
    top: 14rem;
    left: 12rem;
    transition: all 1s ease-in-out;
    z-index: 2;
  }

  .icon_data_base05 {
    width: 4.25rem;
    height: 4.25rem;
    display: block;
    position: absolute;
    top: 10.5rem;
    left: 14.6rem;
    transition: all 1s ease-in-out;
    z-index: 1;
  }

  .icon_data_base06 {
    width: 5.4rem;
    height: 5.4rem;
    display: block;
    position: absolute;
    top: 6.5rem;
    left: 16.75rem;
    transition: all 1s ease-in-out;
    z-index: 2;
  }

  /*业务合作模块*/

  .business-mian-business {
    background: #fff;
    padding: 0 1.12rem;
    box-sizing: border-box;

  }

  .business-mian-business .container {
    max-width: 375px !important;
  }

  .business-mian-business .container .row {
    margin: 0;
  }

  .business-mian-business .business-main-box {
    width: 100%;
    box-shadow: 0px 0.125rem 1.5rem 0px rgba(68, 62, 79, 0.12);
    border-radius: 12px;
    display: flex;
    align-items: center;
    height: 5rem;
    margin-top: 0.05rem;
    padding: 2rem 0 2rem 1.3rem;
    box-sizing: border-box;
  }

  .business-mian-business .business-main-box .icon {
    width: 1rem;
    height: 1rem;
    box-sizing: border-box;
    margin-top: -0.2rem;
  }

  .business-mian-business .business-main-box .desc {
    font-size: 1rem;
    margin-left: 0.4rem;
  }

  .business-mian-business .business-main-box .icon img {
    width: 1rem;
    height: 1rem;
    vertical-align: initial !important;
  }

  .app-business-model {
    height: 0 !important;
  }

  .bag-main-box {
    background-size: 2rem 1.6rem;
  }

  .bag-main-box2 {
    background-size: 2rem 1.6rem;
  }

  .bag-main-box3 {
    background-size: 2rem 1.6rem;
  }

  .bag-main-box4 {
    background-size: 2rem 1.6rem;
  }

  .bag-main-box5 {
    background-size: 2rem 1.6rem;
  }

  .bag-main-box6 {
    background-size: 2rem 1.6rem !important;
    background-position: 93% 50%;
  }

  .bag-main-box7 {
    background: initial;
    background: url(../../resource/bg_base_data05.png) no-repeat !important;
    background-size: 2rem 1.6rem !important;
    background-position: 93% 50% !important;
  }

  /*景合亿家的轮播*/
  .home-swiper-jinghe {
    display: none !important;
    ;
  }

  .home-swiper-jinghe-app {
    display: block;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background: url(../../resource/jinghe-swiper-font.jpg) no-repeat;
    background-size: cover;
    position: relative;
    padding: 0;
    margin-top: 0;
  }

  .home-swiper-jinghe-app img {
    width: 101% !important;
  }

  /* .home-swiper-jinghe-app .carousel-inner {
    width: 100%;
    height: 12rem;
    box-sizing: border-box;
  }

  .home-swiper-jinghe-app .carousel-inner .carousel-item {
    width: 100%;
    height: 12rem;
    border: 1px solid;
    box-sizing: border-box;
    display: block !important;
  }

  .home-swiper-jinghe-app .carousel-inner .carousel-item img {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    object-fit: cover;
  } */

  /*部分合作案例*/
  .pc-case-carousel {
    display: none;
  }

  .coo-appmySwiper {
    display: block;
    margin-top: 1.2rem;
    position: relative;
  }

  .coo-appmySwiper .swiper-slide .box {
    width: 100%;
    height: 12.7rem;
    position: relative;
  }

  .coo-appmySwiper .swiper-slide .box .IMG {
    width: 100%;
    height: 12.7rem;
  }

  .coo-appmySwiper .swiper-slide .box .IMG img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: contain;
  }

  .coo-appmySwiper .swiper-slide .box .desc {
    position: absolute;
    bottom: 0.6rem;
    left: 1.5rem;
    z-index: 2;
    color: #fff;
    font-size: 0.75rem;
  }

  .coo-appmySwiper .swiper-button-next img,
  .coo-appmySwiper .swiper-button-prev img {
    width: 1rem !important;
    height: auto !important;
  }

  .coo-appmySwiper .swiper-button-next {
    right: 1.5rem !important;
    border: initial !important;
    border-color: inherit !important;
    outline: none !important;
  }

  .coo-appmySwiper .swiper-button-prev {
    left: 1.5rem !important;
    border: none !important;
    outline: none !important;
  }

  .coo-appmySwiper .swiper-button-next::after {
    content: '' !important;
    border: initial !important;
  }

  .coo-appmySwiper .swiper-button-prev::after {
    content: '' !important;
  }

  .coo-appmySwiper .swiper-slide .box .desc .more {
    display: flex;
    align-items: center;
  }

  .coo-appmySwiper .swiper-slide .box .desc .more img {
    width: 0.3rem;
    height: auto;
    margin-top: -0.1rem;
    margin-left: 0.3rem;
  }

  .coo-appmySwiper .swiper-horizontal>.swiper-pagination-bullets,
  .coo-appmySwiper .swiper-pagination-bullets.swiper-pagination-horizontal,
  .coo-appmySwiper .swiper-pagination-custom,
  .coo-appmySwiper .swiper-pagination-fraction {
    top: inherit !important;
    bottom: 0.6rem !important;
  }

  .coo-appmySwiper .swiper-pagination-bullet {
    border-radius: initial !important;
    width: 1rem !important;
    height: 0.2rem !important;
    background: #999999 !important;
  }

  .coo-appmySwiper .swiper-pagination-bullet-active {
    background: #fff !important;
    outline: none !important;

  }

  /*更多案例*/
  .coo-more-btn {
    width: 20.2rem;
    height: 2.6rem;
    border-radius: 2px;
    text-align: center;
    color: #333333;
    line-height: 2.5rem;
    border: 1px solid #999999;
    margin: 0 auto;
    margin-top: 1rem;
    font-size: 0.875rem;
    cursor: pointer;
  }
}