.bg-light90 {
  background-color: rgba(255, 255, 255, 0.9);
}
.nav-masthead {
  flex-grow: 0;
}

/*标题*/
.title-hr {
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
}

.title-hr .box {
  margin: auto;
}

.title-hr .box .title {
  width: 100%;
  text-align: center;
  font-size: 1.6rem;
  font-weight: 650;
  color: #333333;
}

.title-hr .box .hr {
  font-size: 1rem;
  font-weight: 400;
  color: #ebe2e2;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  margin-top: 1rem;
}

.title-hr .box .hr span {
  width: 5.5rem;
  height: 0.0625rem;
  display: block;
  background: #D8D8D8;
  margin: 0 0.5rem;
}

/*
 * 深色主题
 */

.navbar-dark .nav-masthead .nav-link {
  background-color: transparent;
  border-bottom: 0.25rem solid transparent;
}

.navbar-dark .navbar-toggler-icon {
  background-image: url(../resource/app_header_right_logo.png) !important;
}

.navbar-dark .nav-masthead .active {
  color: #fff;
  border-bottom-color: rgba(255, 255, 255, 1);
}

/*
   * 浅色主题
   */
.navbar-light .nav-masthead .nav-link {
  background-color: transparent;
  border-bottom: 0.25rem solid transparent;
}



// logo样式
.logo {
  width: 115px;
  height: 30.6px;
  background-repeat: no-repeat;
  background-size: contain;
}

.navbar-dark .logo {
  background-image: url(../resource/logo.png);
}

.navbar-light .logo {
  transition: 0.5s;
  background-image: url(../resource/logo2.png);
}

.cover-container h1 {
  margin-top: 170px;
  text-align: center;
  font-size: 40px;
  font-weight: 500;
  color: #ffffff;
  letter-spacing: 12px;
  text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
}

.cover-container h1 span {
  display: block;
  font-size: 20px;
  font-weight: 500;
  color: #ffffff;
  line-height: 28px;
  letter-spacing: 4px;
  text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.52);
  text-transform: Uppercase;
}


.title-h2 {
  padding-top: 85px;
  font-size: 26px;
  font-weight: 600;
  color: #333333;
  line-height: 37px;
  cursor: default;
}

.title-english {
  padding-bottom: 55px;
  font-size: 16px;
  font-weight: 400;
  color: rgba(51, 51, 51, 0.5);
  text-transform: uppercase;
  line-height: 22px;
  cursor: default;
}

.section-sub-title {
  padding: 0 20px;
  margin: 20px 0;
  line-height: 2px;
  border-left: 90px solid #ddd;
  border-right: 90px solid #ddd;
  cursor: default;
}

@media screen and (min-width: 1012px) {
  .navbar {
    max-width: 1920px !important;
    padding: 0 14rem;
    box-sizing: border-box;
    margin: 0 auto;
  }

  .navbar-dark {
    background: rgba(0, 79, 17, 0.04);
    backdrop-filter: blur(10px);
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 2rem !important;
    padding-left: 2rem !important;

  }

  .navbar-light .nav-masthead .active {
    border-bottom: initial !important;
    background-image: url(./../resource/bg_header.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 0;
    border-bottom: initial !important;
    color: #fff !important;
    // border-bottom-color:#00A862;
    border: none;
  }

  .navbar-dark .nav-masthead .active {
    background-image: url(./../resource/bg_header.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 0;
    border-bottom: initial !important;
  }

  .logo {
    width: 136px;
    height: 35px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .footer-content {
    width: 100%;
    height: 53rem;
    background: url(../resource/bg_footer.png) no-repeat;
    background-size: cover;
    background-color: #fff;
    max-width: 1920px !important;
    box-sizing: border-box;
    position: relative;
    margin: 0 auto;
  }

  .footer-pagebox {
    width: 95rem;
    color: #fff;
    position: absolute;
    bottom: 5rem;
    left: 50%;
    transform: translateX(-50%);
  }

  .footer-postion {
    position: relative;
  }

  .footer-position-content {
    // position: absolute;
    // top: 2.4rem;
    // right: 0;
  }

  .footer-icon {
    width: 16.8rem;
    height: 4.1rem;
    background: url(../resource/logo_footer.png) no-repeat;
    background-size: cover;
  }

  .footer-desc {
    display: none;
  }

  .pc-footer-desc {
    font-size: 1.3rem;
    display: block;
  }

  .pc-footer-desc div {
    margin-top: 0.5rem;
  }

  .pc-hr-v {
    width: 0px;
    height: 9.7rem;
    opacity: 0.31;
    border-right: 1px solid #FFFFFF;
    margin: 0 auto;
    margin-top: 1.5rem;
  }

  .yunlvbox {
    display: flex;
    justify-content: flex-end;
  }

  .yunlv {
    width: 11.25rem;
    height: 11.25rem;
    box-sizing: border-box;
    text-align: center;
  }

  .yunlv span {
    font-size: 1rem;
    font-weight: 400;
    color: #FFFFFF;
    opacity: 0.5;
  }

  .yunlv img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-bottom: 5px;
  }

  .footer-desc span {
    color: rgba(255, 255, 255, 0.3);
    font-size: 1.3rem;
  }

  .footer-address-label {
    font-size: 1.5rem;
  }

  .footer-address {
    font-size: 0.875rem;
    font-weight: 400;
    color: #FFFFFF;
    opacity: 0.6;
    margin-top: 1rem;
  }

  .footer-hr {
    width: 100%;
    height: 0;
    border-bottom: 0.2px solid #ffffff;
    opacity: 0.6;
    margin-top: 5.3rem;
  }

  .footer-copyright {
    width: 100%;
    text-align: center;
    font-size: 0.75rem;
    margin-top: 2.2rem;
    opacity: 0.8;
  }

  .footer-copyright a {
    text-decoration: none;
    color: #fff;
  }

  .app-footer-copyright {
    display: none;
  }
}

@media screen and (min-width:1009px) and(max-width:1011px) {
  .footer-content {
    width: 100%;
    height: 63.75rem;
    background: url(../resource/bg_app_footer.png) no-repeat;
    background-size: cover;
    background-color: #fff;
    padding-top: 27.5rem;
    box-sizing: border-box;
  }

  .footer-pagebox {
    width: 90rem;
    height: 100%;
    color: #fff;
  }

  .footer-postion {
    position: relative;
  }

  .footer-position-content {
    position: absolute;
    top: 0.6rem;
    right: 0;
  }

  .footer-icon {
    width: 10rem;
    height: 2.5rem;
    background: url(../resource/app_footer_logo.png) no-repeat;
    background-size: cover;
  }

  .footer-desc {
    display: none;
  }

  .pc-footer-desc {
    font-size: 1.3rem;
    display: block;
  }

  .pc-footer-desc div {
    margin-top: 0.5rem;
  }

  .pc-hr-v {
    width: 1px;
    height: 9.7rem;
    opacity: 0.31;
    border: 1px solid #FFFFFF;
    margin: 0 auto;
  }

  .yunlvbox {
    display: flex;
    justify-content: flex-end;
  }

  .yunlv {
    width: 11.25rem;
    height: 11.25rem;
    box-sizing: border-box;
    text-align: center;
  }

  .yunlv span {
    font-size: 1rem;
    font-weight: 400;
    color: #FFFFFF;
    opacity: 0.5;
  }

  .yunlv img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-bottom: 5px;
  }

  .footer-desc span {
    color: rgba(255, 255, 255, 0.3);
    font-size: 0.75rem;
  }

  .footer-address-label {
    font-size: 0.875rem;
  }

  .footer-address {
    font-size: 0.625rem;
    font-weight: 400;
    color: #FFFFFF;
    opacity: 0.6;
    margin-top: 1rem;
  }

  .footer-hr {
    width: 100%;
    height: 0;
    border-bottom: 0.0625rem solid #ffffff;
    opacity: 0.6;
    margin-top: 15.3rem;
  }

  .footer-copyright {
    width: 100%;
    text-align: center;
    font-size: 0.75rem;
    margin-top: 2.2rem;
  }

  .footer-copyright a {
    text-decoration: none;
    color: #fff;
  }

  .app-footer-copyright {
    display: none;
  }
}

@media screen and (min-width:993px) and(max-width:1008px) {
  .footer-content {
    width: 100%;
    height: 44.75rem;
    background: url(../resource/bg_app_footer.png) no-repeat;
    background-size: cover;
    background-color: #fff;
    box-sizing: border-box;
    position: relative;

  }

  .footer-pagebox {
    width: 70rem;
    color: #fff;
    position: absolute;
    bottom: 5rem;
    left: 50%;
    transform: translateX(-50%);
  }

  .footer-postion {
    position: relative;
  }



  .footer-icon {
    width: 10rem;
    height: 2.5rem;
    background: url(../resource/app_footer_logo.png) no-repeat;
    background-size: cover;
  }

  .footer-desc {
    display: none;
  }

  .pc-footer-desc {
    font-size: 1.3rem;
    display: block;
  }

  .pc-footer-desc div {
    margin-top: 0.5rem;
  }

  .pc-hr-v {
    width: 1px;
    height: 9.7rem;
    opacity: 0.31;
    border: 1px solid #FFFFFF;
    margin: 0 auto;
  }

  .yunlvbox {
    display: flex;
    justify-content: flex-end;
  }

  .yunlv {
    width: 11.25rem;
    height: 11.25rem;
    box-sizing: border-box;
    text-align: center;
  }

  .yunlv span {
    font-size: 1rem;
    font-weight: 400;
    color: #FFFFFF;
    opacity: 0.5;
  }

  .yunlv img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-bottom: 5px;
  }

  .footer-desc span {
    color: rgba(255, 255, 255, 0.3);
    font-size: 0.75rem;
  }

  .footer-address-label {
    font-size: 0.875rem;
  }

  .footer-address {
    font-size: 0.625rem;
    font-weight: 400;
    color: #FFFFFF;
    opacity: 0.6;
    margin-top: 1rem;
  }

  .footer-hr {
    width: 100%;
    height: 0;
    border-bottom: 0.0625rem solid #ffffff;
    opacity: 0.6;
    margin-top: 5.3rem;
  }

  .footer-copyright {
    width: 100%;
    text-align: center;
    font-size: 0.75rem;
    margin-top: 2.2rem;
    opacity: 0.8;
  }

  .footer-copyright a {
    text-decoration: none;
    color: #fff;
  }

  .app-footer-copyright {
    display: none;
  }
}

@media screen and (min-width: 768px) and (max-width:992px) {
  .footer-content {
    width: 100%;
    height: 40.75rem;
    background: url(../resource/bg_app_footer.png) no-repeat;
    background-size: cover;
    background-color: #fff;
    box-sizing: border-box;
    position: relative;
  }

  .footer-pagebox {
    width: 100%;
    color: #fff;
    position: absolute;
    bottom: 5rem;
    left: 50%;
    transform: translateX(-50%);
  }

  .footer-postion {
    position: relative;
  }

  .footer-position-content {
    position: absolute;
    top: 0.6rem;
    right: 0;
  }

  .footer-icon {
    width: 10rem;
    height: 2.5rem;
    background: url(../resource/app_footer_logo.png) no-repeat;
    background-size: cover;
  }

  .footer-desc {
    display: none;
  }

  .pc-footer-desc {
    font-size: 1rem;
    display: block;
  }

  .pc-footer-desc div {
    margin-top: 0.5rem;
  }

  .pc-hr-v {
    width: 1px;
    height: 9.7rem;
    opacity: 0.31;
    border: 1px solid #FFFFFF;
    margin: 0 auto;
  }

  .yunlvbox {
    display: flex;
    justify-content: flex-end;
  }

  .yunlv {
    width: 6rem;
    height: 6rem;
    box-sizing: border-box;
    text-align: center;
  }

  .yunlv span {
    font-size: 0.5rem;
    font-weight: 400;
    color: #FFFFFF;
    opacity: 0.5;
  }

  .yunlv img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-bottom: 5px;
  }

  .footer-desc span {
    color: rgba(255, 255, 255, 0.3);
    font-size: 0.75rem;
  }

  .footer-address-label {
    font-size: 0.875rem;
  }

  .footer-address {
    font-size: 0.625rem;
    font-weight: 400;
    color: #FFFFFF;
    opacity: 0.6;
    margin-top: 1rem;
  }

  .footer-hr {
    width: 100%;
    height: 0;
    border: 1px solid #ffffff;
    opacity: 0.6;
    margin-top: 5.3rem;
  }

  .footer-copyright {
    width: 100%;
    text-align: center;
    font-size: 0.75rem;
    margin-top: 2.2rem;
    opacity: 0.8;
  }

  .footer-copyright a {
    text-decoration: none;
    color: #fff;
  }

  .app-footer-copyright {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .navbar {
    max-width: 767px !important;
    padding: 0 1rem;
    box-sizing: border-box;
  }

  .navbar-dark .navbar-nav .nav-link {
    color: #333333;
  }

  .navbar-dark .nav-masthead .active {
    color: #00A862 !important;
    border-bottom: none;

  }

  .navbar-light .nav-masthead .active {
    color: #00A862 !important;
    border: none;
  }

  .navbar-toggler:focus {
    // border: none !important;
    box-shadow: initial !important;
    outline: none !important;
  }

  .navbar-dark .logo {
    background-image: url(../resource/logo.png);
    // margin-left: 26px;
  }

  .footer-content {
    width: 100%;
    height: 37.75rem;
    background: url(../resource/bg_app_footer.png) no-repeat;
    background-size: cover;
    background-color: #fff;
    padding-top: 7.5rem;
    box-sizing: border-box;
  }

  .footer-pagebox {
    width: 20.5rem;
    height: 100%;
    color: #fff;
  }

  .footer-icon {
    width: 10rem;
    height: 2.5rem;
    background: url(../resource/app_footer_logo.png) no-repeat;
    background-size: cover;
  }

  .footer-desc {
    font-size: 0.75rem;
    margin-top: 0.5rem;
    display: block;
  }


  .pc-footer-desc {
    display: none;
  }

  .pc-footer-desc div {
    margin-top: 0.5rem;
  }

  .pc-hr-v {
    display: none;
  }

  .yunlvbox {
    display: flex;
    justify-content: flex-start;
  }

  .yunlv {
    width: 5.6rem;
    height: 5.6rem;
    box-sizing: border-box;
    text-align: center;
    margin-top: 1.8rem;
    margin-bottom: 1.8rem;
  }

  .yunlv span {
    font-size: 0.5rem;
    font-weight: 400;
    color: #FFFFFF;
    opacity: 0.5;
  }

  .yunlv img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-bottom: 3px;
  }

  .footer-desc span {
    color: rgba(255, 255, 255, 0.3);
    font-size: 0.75rem;
  }

  .footer-address-label {
    font-size: 0.875rem;
    margin-top: 1.8rem;
  }

  .footer-address {
    font-size: 0.625rem;
    font-weight: 400;
    color: #FFFFFF;
    opacity: 0.6;
    margin-top: 0.4rem;
  }

  .footer-hr {
    width: 19.1rem;
    height: 0;
    border-bottom: 0.0625rem solid #ffffff;
    opacity: 0.6;
    margin-top: 1.75rem;
    margin-left: 0.4rem;
  }

  .footer-copyright {
    display: none;
  }

  .app-footer-copyright {
    display: block;
  }

  .app-footer-copyright {
    width: 100%;
    font-size: 0.625rem;
    margin-top: 1.75rem;
    opacity: 0.8;
  }

  .app-footer-copyright a {
    text-decoration: none;
    color: #fff;
  }



  .title-h2 {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 25px;
    display: flex;
    justify-content: flex-start;
    padding: 30px 0 20px 16px;
  }

  .title-english {
    padding: 0;
    display: none;
  }

  .section-sub-title {
    display: none;
  }

  /*标题*/
  .title-hr {
    width: 100%;
    height: 100%;
    background: #fff;
    display: flex;
  }

  .title-hr .box {
    margin: auto;
  }

  .title-hr .box .title {
    width: 100%;
    text-align: center;
    font-size: 1rem;
    font-weight: 650;
    color: #333333;
  }

  .title-hr .box .hr {
    font-size: 0.68rem;
    font-weight: 400;
    color: #ebe2e2;
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    margin-top: 1rem;
  }

  .title-hr .box .hr span {
    width: 2.5rem;
    height: 0.0625rem;
    display: block;
    background: #D8D8D8;
    border: none;
    border-bottom: 1px solid #D8D8D8;
    margin: 0 0.5rem;
  }
}