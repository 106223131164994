$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1012px,
  xl: 1230px,
  xxl:1400px
);

$container-max-widths: (
  xs:375,
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1200px,
  xxl:1400px
);

$grid-gutter-width: 0.625rem;
$gutters: (
  0: 0,
  // 10px
  1: $grid-gutter-width,
  // 20px
  2: $grid-gutter-width * 2.5,
  // 30px
  3: $grid-gutter-width * 3,
  // 40px
  4: $grid-gutter-width * 4,
  // 50px
  5: $grid-gutter-width * 5,
  // 80px
  8: $grid-gutter-width * 8,
);

@import "../../node_modules/bootstrap/scss/bootstrap";
